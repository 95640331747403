import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Layout from "components/Layout";
import Login from "components/Login";
import { useRouter } from "next/router";
import { bindActionCreators } from "redux";
import { emailLogin, fetchUserDetails } from "actions/auth";
import { connect } from "react-redux";
import { COLORS } from "utils/styles";
import PATHS from "utils/paths";
import { scrollTo } from "utils/view";
import { getJWT } from "utils/auth";
import { getRedirectTo, redirectTo } from "utils/route";
import isError from "lodash/isError";
import { verifyRequest, SECURE_ACTIONS } from "utils/security";
import { landingStyles } from "components/Landing/landingStyles";
import { clearMockReducers } from "utils/hooks";
import Footer from "components/Footer";
import { useAuthLoading } from "utils/selectors";

const LoginPage = (props) => {
  clearMockReducers && clearMockReducers();
  const { actions } = props;
  const authLoading = useAuthLoading();
  const router = useRouter();
  const canFetchUserDetails = getJWT() && !authLoading;

  useEffect(() => {
    scrollTo();
    canFetchUserDetails &&
      actions.fetchUserDetails(true, (err) => {
        if (isError(err)) {
          router.replace(PATHS.LOGIN);
        } else {
          const destination = getRedirectTo(router, PATHS.HUB);
          router.replace(destination);
        }
      });
  }, [canFetchUserDetails]);

  /**
   * Submit signup request for the user and validate via recpatcha
   * @param {Object} values - signup form values
   * @param {String} values.email - email of user
   * @param {String} values.password - password for account
   * @param {Function} loginCb
   */
  const login = (values, loginCb) => {
    verifyRequest({
      action: SECURE_ACTIONS.LOGIN,
      onSuccess: () => {
        actions.emailLogin(values, loginCb);
      },
      onError: (err) => {
        loginCb(err);
      }
    });
  };

  /**
   * Redirect to path if param is present
   */
  const requestSuccess = () => {
    redirectTo(router, PATHS.ONBOARDING);
  };

  return (
    <Layout>
      {() => (
        <div
          className="h-100 w-100"
          style={{ backgroundColor: COLORS.whitesmoke }}
        >
          <Login
            routerQuery={router.query}
            login={login}
            withOauth
            requestSuccess={requestSuccess}
          />
          <div className="mw8 center ">
            <div className="hairline-1 bt">
              <Footer />
            </div>
          </div>

          <style jsx>{landingStyles}</style>
        </div>
      )}
    </Layout>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      emailLogin,
      fetchUserDetails
    },
    dispatch
  )
});

LoginPage.propTypes = {
  actions: PropTypes.object.isRequired
};

export default connect(null, mapDispatchToProps)(LoginPage);
