import isEmpty from "lodash/isEmpty";
import reduce from "lodash/reduce";
import * as yup from "yup";

const ValidationsFromSpec = (type, validation) => {
  if (type && !isEmpty(validation)) {
    const shape = reduce(
      validation,
      (validMemo, options, key) => {
        const thing = reduce(
          options,
          (optMemo, option) => {
            return optMemo[option.method](option.options);
          },
          yup
        );

        validMemo[key] = thing;
        return validMemo;
      },
      {}
    );

    return yup[type]().shape(shape);
  }

  return null;
};

export default ValidationsFromSpec;
