import React, { useState, Fragment } from "react";
import { Formik } from "formik";
import validationsFromSpec from "utils/validationsFromSpec";
import validation from "./config/validation";
import data from "./config/data";
import PropTypes from "prop-types";
import Link from "next/link";
import Button from "components/Form/fields/Button";
import OAuthButtons from "components/OAuthButtons";
import RecaptchaNotice from "components/RecaptchaNotice";
import Separator from "components/Separator";
import { formatValidationError } from "utils/form";
import { styles } from "components/HeroSection/styles";
import { AUTH_AUTO_ATTRS } from "components/Form/fields/constants";
import HoverIconButton from "components/HoverIconButton";
import { COLORS } from "utils/styles";

const Login = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const { login, requestSuccess, routerQuery } = props;

  const onSubmit = (values, { setSubmitting, setErrors }) => {
    login(values, (err, data) => {
      setSubmitting(false);
      if (err) {
        console.error("User Login error: ", err);
        setErrors({ email: err.message });
      } else {
        requestSuccess(data);
      }
    });
  };

  return (
    <Fragment>
      <div className="dib hero__brand--priceblocs mv4 pl3 w-100 z-1">
        <div className="hero__logo contain bg-center h-100 w-100" />
      </div>
      <Formik
        initialValues={data(routerQuery)}
        validationSchema={validationsFromSpec("object", validation)}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => {
          const emailProps = {
            className: "pa2 bbox input-reset ba br2 b--black-50 bg-white w-100",
            type: "email",
            name: "email",
            onChange: handleChange,
            onBlur: handleBlur,
            value: values.email,
            ...AUTH_AUTO_ATTRS
          };

          const passwordProps = {
            className: "pa2 bbox input-reset ba br2 b--black-50 bg-white w-100",
            type: isVisible ? "text" : "password",
            name: "password",
            onChange: handleChange,
            onBlur: handleBlur,
            value: values.password,
            ...AUTH_AUTO_ATTRS,
            autoComplete: "current-password"
          };

          return (
            <main className="ph3 pb3 black-80 h-100">
              <form
                className="app__action--form center shadow-0 hairline-1 pv4 ph3 ph4-ns bg-white ba br2"
                onSubmit={handleSubmit}
              >
                <fieldset id="sign_up" className="ba b--transparent ma0 pa0">
                  <legend className="f5 fw6 ph0 mh0 tc center">
                    Sign in to your account
                  </legend>
                  <div className="mt3">
                    <label className="db fw5 lh-copy f6" htmlFor="email">
                      Email
                    </label>
                    <input {...emailProps} />
                    {touched.email && errors.email && (
                      <div className="f7 pt2 fw5 color-error">
                        {formatValidationError(errors.email)}
                      </div>
                    )}
                  </div>
                  <div className="mv3">
                    <div className="flex flex-row items-center justify-between">
                      <label className="db fw5 lh-copy f6" htmlFor="password">
                        Password
                      </label>
                      <div className="flex items-center justify-center">
                        <HoverIconButton
                          size={12}
                          onClick={() => setIsVisible(!isVisible)}
                          tooltip={isVisible ? "Hide" : "Show"}
                          color={isVisible ? COLORS.blue : COLORS.primary}
                          type={isVisible ? "eye" : "eyeClosed"}
                        />
                      </div>
                    </div>
                    <div className="w-100 relative">
                      <input {...passwordProps} />
                    </div>
                    {touched.password && errors.password && (
                      <div className="f7 pt2 fw5 color-error">
                        {formatValidationError(errors.password)}
                      </div>
                    )}
                  </div>
                </fieldset>
                <div className="pv1">
                  <Button
                    type="submit"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    copy="Sign in"
                    classes={{
                      button:
                        "fw6 br2 ph3 pv2 border-box input-reset ba b--black bg-transparent pointer f6 dib link items-center flex w-100"
                    }}
                  />
                </div>
                <Fragment>
                  <div className="w-100 pv3 tc f6 fw5">OR</div>
                </Fragment>
                <OAuthButtons />
                <Separator customClasses={{ container: "w-100 pv3 dib" }} />
                <ul className="flex ma0 pa0 list items-center justify-center">
                  <li className="di">
                    <Link href="/reset">
                      <a className="f6 link color-primary fw5 link__decoration--hover">
                        {"Forgot password?"}
                      </a>
                    </Link>
                  </li>
                  <li className="auth__footerLink--dot di">
                    <Link href="/signup">
                      <a className="f6 link color-primary fw5 link__decoration--hover">
                        Sign up for an account
                      </a>
                    </Link>
                  </li>
                </ul>
              </form>
              <div
                className="flex items-center mw6 pt3 center"
                style={{ maxWidth: 380 }}
              >
                <RecaptchaNotice />
              </div>
            </main>
          );
        }}
      </Formik>
      <style jsx>{styles}</style>
    </Fragment>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  withOauth: PropTypes.bool.isRequired,
  requestSuccess: PropTypes.func.isRequired,
  routerQuery: PropTypes.shape({
    email: PropTypes.string
  })
};

export default Login;
