export default {
  password: [
    {
      method: "string"
    },
    {
      method: "required",
      options: "Password is required"
    },
    {
      method: "min",
      options: 8
    }
  ],
  email: [
    {
      method: "string"
    },
    {
      method: "email",
      options: "Invalid email address"
    },
    {
      method: "required",
      options: "Email is required"
    }
  ]
};
